@import "~core/abstract/all";
@import "~bootstrap/scss/mixins";

.mobile-category-nav{
    margin: auto;

    .mobile-category-nav__categories{
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: stretch;
      column-gap: 8px;
      overflow-x: auto;
      padding: 0 16px;
    }
    .b-image-and-text{
      width: 100%;
      min-width: calc(84/430 *100vw);
      
      .b-image-and-text__link {
        font-size: 10px;
        letter-spacing: 0px;
      }
    }
}